var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"ql-wrapper"},[_c('div',{staticClass:"ql-container ql-snow"},[_c('div',{ref:"viewer",staticClass:"viewer ql-editor",on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();_vm.editorModal = true}}})])]),_c('b-form-input',{staticStyle:{"height":"0","padding":"0","margin":"0","border":"0"},attrs:{"required":""},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}}),_c('div',[(_vm.answerText.content.links)?_c('ol',{staticClass:"mt-2 pl-4"},_vm._l((_vm.answerText.content.links),function(link){return _c('li',{key:link.startPosition,staticClass:"text-muted",staticStyle:{"font-size":"0.9em"}},[_c('small',{staticClass:"text-muted"},[_vm._v("["+_vm._s(_vm.answerText.content.message.substring( link.startPosition, link.endPosition + 1 ))+"] ("+_vm._s(link.url || _vm.getIntentName(link.intentId))+")")])])}),0):_vm._e()])],1)],1),_c('b-modal',{attrs:{"size":"lg","centered":"","hide-header":"","hide-footer":"","no-close-on-backdrop":""},on:{"shown":() => {
        let length = this.quillEditor.getLength();
        this.quillEditor.focus();
        this.quillEditor.setSelection(length, 0);
      }},model:{value:(_vm.editorModal),callback:function ($$v) {_vm.editorModal=$$v},expression:"editorModal"}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],attrs:{"variant":"outline-primary","size":"sm"}},[_vm._v("Show HTML ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-1"}},[_c('div',{staticClass:"html-display",attrs:{"contenteditable":"false","title":"text in html"}},[_vm._v(" "+_vm._s(_vm.answer)+" ")])])],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('vue-editor',{ref:"editor",staticClass:"vue-editor",attrs:{"id":"editor","editorOptions":_vm.editorOptions},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.updateAnswerText}},[_vm._v("Apply ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"size":"sm","variant":"secondary"},on:{"click":_vm.answerTextUpdated}},[_vm._v("Cancel ")])],1)],1)],1),_c('b-modal',{attrs:{"centered":"","hide-header":"","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.linkModal),callback:function ($$v) {_vm.linkModal=$$v},expression:"linkModal"}},[_c('b-form',{ref:"linkForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addLink.apply(null, arguments)}}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":"","small":""},on:{"input":(i) => {
                  if (i === 1) {
                    this.linkForm.intentId = null;
                  }
                }}},[_c('b-tab',{attrs:{"title":"Intent Link","lazy":""}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12","lg":"3","xl":"2"}},[_c('label',{attrs:{"for":"link-form-text"}},[_vm._v("Text")])]),_c('b-col',[_c('b-input',{attrs:{"id":"link-form-text","size":"sm","required":"","disabled":""},model:{value:(_vm.linkForm.text),callback:function ($$v) {_vm.$set(_vm.linkForm, "text", $$v)},expression:"linkForm.text"}})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12","lg":"3","xl":"2"}},[_vm._v("Intent")]),_c('b-col',[_c('treeselect',{staticStyle:{"width":"calc(100% - 18px)"},attrs:{"required":"","options":_vm.intents,"normalizer":(node) => {
                          return {
                            id: node.intentId,
                            label: node.intentName,
                          };
                        }},model:{value:(_vm.linkForm.intentId),callback:function ($$v) {_vm.$set(_vm.linkForm, "intentId", $$v)},expression:"linkForm.intentId"}})],1)],1)],1),_c('b-tab',{attrs:{"title":"Html Link","lazy":""},on:{"":function($event){}}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12","lg":"3","xl":"2"}},[_c('label',{attrs:{"for":"link-form-text"}},[_vm._v("Text")])]),_c('b-col',[_c('b-input',{attrs:{"id":"link-form-text","size":"sm","required":"","disabled":""},model:{value:(_vm.linkForm.text),callback:function ($$v) {_vm.$set(_vm.linkForm, "text", $$v)},expression:"linkForm.text"}})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12","lg":"3","xl":"2"}},[_vm._v("Link")]),_c('b-col',[_c('b-input',{attrs:{"type":"url","size":"sm"},model:{value:(_vm.linkForm.url),callback:function ($$v) {_vm.$set(_vm.linkForm, "url", $$v)},expression:"linkForm.url"}})],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right ml-2",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addLink}},[_vm._v(" Add link ")]),_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){_vm.linkModal = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }